const cleanPayload = payload => {
  const payloadKeys = Object.keys(payload)

  // strip payload of stringified falsey values
  payloadKeys.map(key => {
    const isFalseyValue =
      payload[key] === 'null' || payload[key] === 'undefined' || payload[key] === ''

    if (isFalseyValue) {
      delete payload[key]
    }
  })

  return payload
}

export default cleanPayload
