/* eslint-disable no-console */
/* eslint-disable no-undef */

const logError = err => {
  if (process.env.NODE_ENV === 'development') {
    console.error('An error occurred:', err)
  }
}

export default logError
