import { parse, stringify } from 'qs'
import { navigate } from '@reach/router'

const url = (urlString, params = {}) => {
  const url = new URL(urlString)
  const combinedParams = { ...parse(url.search.slice(1)), ...params }
  url.search = stringify(combinedParams)
  return url.toString()
}

const dissmissEmailConfirmedMessage = () => {
  const urlParams = parse(location.search.replace('?', ''))
  delete urlParams['emailConfirmed']
  const newParams = stringify(urlParams)
  const appendString = `?${newParams}`
  navigate(appendString, { replace: true })
}

const displayDeleteSuccessMessage = () => {
  const urlParams = parse(location.search.replace('?', ''))
  const newParams = stringify({ ...urlParams, deleted: true })
  const appendString = `?${newParams}`
  navigate(appendString, { replace: true })
}

const displayUpdateSuccessMessage = () => {
  const urlParams = parse(location.search.replace('?', ''))
  const newParams = stringify({ ...urlParams, success: true })
  const appendString = `?${newParams}`
  navigate(appendString, { replace: true })
}

const displayPendingMessage = () => {
  const urlParams = parse(location.search.replace('?', ''))
  const newParams = stringify({ ...urlParams, pending: true })
  const appendString = `?${newParams}`
  navigate(appendString, { replace: true })
}

const displayRecurringSuccess = ({ payload }) => {
  const urlParams = parse(location.search.replace('?', ''))
  const newParams = stringify({
    ...urlParams,
    recurringSuccess: true,
    amount: payload.applicant_commitment_other || payload.applicant_commitment
  })
  const appendString = `?${newParams}`
  navigate(appendString, { replace: true })
}

export default {
  url,
  dissmissEmailConfirmedMessage,
  displayPendingMessage,
  displayRecurringSuccess,
  displayDeleteSuccessMessage,
  displayUpdateSuccessMessage
}
