// config
import constants from 'config/constants'

const deriveGoalSlotLabel = ({ goalType, outcomeTarget = '' }) => {
  const outcomeInteger = parseInt(outcomeTarget) || 1

  if (!goalType) return `${outcomeInteger === 1 ? 'slot' : 'slots'}`

  if (goalType === constants.OPPORTUNITY_GOAL_TYPES.CHALLENGE) {
    return `${outcomeInteger === 1 ? 'challenge' : 'challenges'}`
  }

  if (goalType === constants.OPPORTUNITY_GOAL_TYPES.GOODS) {
    return `${outcomeInteger === 1 ? 'good' : 'goods'}`
  }

  if (goalType === constants.OPPORTUNITY_GOAL_TYPES.VOLUNTEERING) {
    return `${outcomeInteger === 1 ? 'volunteer' : 'volunteers'}`
  }
}

const deriveGoalLabel = ({ goalType, outcomeTarget, skipGoalPrefix }) => {
  const outcomeInteger = parseInt(outcomeTarget) || 1
  const localizedNumber = outcomeInteger.toLocaleString('en-US')
  const prefix = skipGoalPrefix ? '' : 'Goal: '

  if (!goalType)
    return `${prefix}${localizedNumber} ${outcomeInteger === 1 ? 'good deed' : 'good deeds'}`

  if (goalType === constants.OPPORTUNITY_GOAL_TYPES.CHALLENGE) {
    return `${prefix}${localizedNumber} ${outcomeInteger === 1 ? 'challenge' : 'challenges'}`
  }

  if (goalType === constants.OPPORTUNITY_GOAL_TYPES.MONEY) {
    return `${prefix}R${localizedNumber}`
  }

  if (goalType === constants.OPPORTUNITY_GOAL_TYPES.GOODS) {
    return `${prefix}${localizedNumber} ${outcomeInteger === 1 ? 'good' : 'goods'}`
  }

  if (goalType === constants.OPPORTUNITY_GOAL_TYPES.VOLUNTEERING) {
    return `${prefix}${localizedNumber} ${outcomeInteger === 1 ? 'volunteer' : 'volunteers'}`
  }

  // Respond using a custom goal type
  return `${prefix}${localizedNumber} ${
    outcomeInteger === 1 ? goalType.replace(/s$/, '') : goalType
  }`
}

export { deriveGoalLabel, deriveGoalSlotLabel }

export default deriveGoalLabel
