// config
import l from 'config/localization'
import constants from 'config/constants'

// Imported constants
const storyTypes = constants.STORY_TYPES

// assets
import ActivityIcon from 'assets/images/svgs/story-good-deed.svg'
import OppIcon from 'assets/images/svgs/icon-org-page-opportunity.svg'
import OrgIcon from 'assets/images/svgs/icon-org-page-organisation.svg'
import StoryFeaturedIcon from 'assets/images/svgs/story-featured.svg'
import StoryUpdateIcon from 'assets/images/svgs/story-update.svg'
import DonationIcon from 'assets/images/cause-type-icons/money_icon_dark.svg'
import GoodsIcon from 'assets/images/cause-type-icons/goods_icon_dark.svg'
import ChallengeIcon from 'assets/images/cause-type-icons/challenge_icon_dark.svg'
import VolunteerIcon from 'assets/images/cause-type-icons/volunteer_icon_dark.svg'

const deriveAttribution = ({ storyBeneficiary }) => {
  if (!storyBeneficiary) return ''
  if (!storyBeneficiary.name) return ''

  return {
    beneficiaryName: `${storyBeneficiary.name}`,
    beneficiaryLink: storyBeneficiary.link
  }
}

const noBeneficiaryStoryTypes = [storyTypes.ORG_FEATURE, storyTypes.ORG_UPDATE]

const deriveStoryTypeIcon = ({ story }) => {
  const storyType = story.story_type

  switch (storyType) {
    case storyTypes.GOOD_DEED:
      return ActivityIcon
    case storyTypes.GOOD_DEED_TEMPLATE:
      return ActivityIcon
    case storyTypes.OPP_FEEDBACK:
      return OppIcon
    case storyTypes.OPP_HOST_FEEDBACK:
      return OppIcon
    case storyTypes.CAMP_FEATURE:
      return StoryFeaturedIcon
    case storyTypes.CAMP_UPDATE:
      return StoryUpdateIcon
    case storyTypes.CHALLENGE_CREATED:
      return OppIcon
    case storyTypes.DGMT_PBO_LISTING:
      return 'CreativeCommonsZero'
    case storyTypes.DGMT_PBO_LISTING_CONFIRMED:
      return 'CreativeCommonsZero'
    case storyTypes.DGMT_DELISTING_FEEDBACK:
      return 'CancelIcon'
    case storyTypes.DGMT_IMPACT_FEEDBACK:
      return 'BodyTextIcon'
    case storyTypes.DONATION_TO_ORG:
      return DonationIcon
    case storyTypes.DONATION_TO_ORG_CONFIRMED:
      return DonationIcon
    case storyTypes.NON_PROFIT_MEMBERSHIP_ADDED:
      return 'CalendarEventFill'
    case storyTypes.OPP_FEATURE:
      return StoryFeaturedIcon
    case storyTypes.OPP_SIGN_UP_CHALLENGE:
      return ChallengeIcon
    case storyTypes.OPP_SIGN_UP_VOLUNTEER:
      return VolunteerIcon
    case storyTypes.OPP_SIGN_UP_GOODS:
      return GoodsIcon
    case storyTypes.OPP_SIGN_UP_MONEY:
      return DonationIcon
    case storyTypes.OPP_SIGN_UP_MONEY_CORPORATE:
      return DonationIcon
    case storyTypes.OPP_SIGN_UP_MONEY_CONFIRMED:
      return DonationIcon
    case storyTypes.OPP_SIGN_UP_MONEY_CORPORATE_CONFIRMED:
      return DonationIcon
    case storyTypes.OPP_CREATED:
      return OppIcon
    case storyTypes.OPP_UPDATE:
      return StoryUpdateIcon
    case storyTypes.ORG_FEATURE:
      return StoryFeaturedIcon
    case storyTypes.ORG_INVITE:
      return OrgIcon
    case storyTypes.ORG_PROFILE_COMPLETE:
      return OrgIcon
    case storyTypes.ORG_UPDATE:
      return StoryUpdateIcon
    case storyTypes.CHALLENGE_CHECKIN:
      return ChallengeIcon
    default:
      return null
  }
}

const deriveGoalTypeIcon = ({ goalType }) => {
  switch (goalType) {
    case constants.OPPORTUNITY_GOAL_TYPES.MONEY:
      return DonationIcon
    case constants.OPPORTUNITY_GOAL_TYPES.VOLUNTEERING:
      return VolunteerIcon
    case constants.OPPORTUNITY_GOAL_TYPES.CHALLENGE:
      return ChallengeIcon
    case constants.OPPORTUNITY_GOAL_TYPES.GOODS:
      return GoodsIcon
    default:
      return null
  }
}

const deriveDonationAmount = ({ goodsDonationsForStory = [], orgGoodsDonationsForStory = [] }) => {
  if (!goodsDonationsForStory.length && !orgGoodsDonationsForStory.length) return 0

  const donationAmounts = [...goodsDonationsForStory, ...orgGoodsDonationsForStory]
    .filter(d => {
      const isExcludeable = [
        constants.DONATION_DETAIL_STATES.REJECTED,
        constants.DONATION_DETAIL_STATES.WITHDREW
      ].includes(d.status)

      return !isExcludeable
    })
    .reduce((sum, accumulator) => {
      return sum + accumulator.amount_donated || 0
    }, 0)

  return donationAmounts
}

const deriveGoodsName = ({ goodsDonationsForStory = [], orgGoodsDonationsForStory = [] }) => {
  if (!goodsDonationsForStory.length && !orgGoodsDonationsForStory.length) return 'goods'

  const itemsNames = [...goodsDonationsForStory, ...orgGoodsDonationsForStory]
    .filter(d => {
      const isExcludeable = [
        constants.DONATION_DETAIL_STATES.REJECTED,
        constants.DONATION_DETAIL_STATES.WITHDREW
      ].includes(d.status)

      return !isExcludeable
    })
    .map(d => d.requested_item)
  if (!itemsNames.length) return 'goods'

  const uniqueItemNames = new Set(itemsNames)
  return uniqueItemNames.length === 1 ? uniqueItemNames[0] : uniqueItemNames.join(' & ')
}

const deriveAction = ({
  applicationWithdrawn,
  goodsDonationsForStory,
  isOppMasonry,
  orgGoodsDonationsForStory,
  storyAmount,
  storyOutcomeStatus,
  storyType
}) => {
  const currencyAmount = storyAmount ? storyAmount.toLocaleString('en-US') : null

  const verb = [
    constants.GOODS_DONATION_CONFIRMATION_OPTS.CONFIRMED,
    constants.APPLICATION_STATUS_NAMES.CONFIRMED
  ].includes(storyOutcomeStatus)
    ? 'donated'
    : 'committed to donate'

  const goodsAmount = deriveDonationAmount({ goodsDonationsForStory, orgGoodsDonationsForStory })
  const goodsName = deriveGoodsName({ goodsDonationsForStory, orgGoodsDonationsForStory })

  switch (storyType) {
    case storyTypes.GOOD_DEED:
      return `did a good deed`
    case storyTypes.GOOD_DEED_TEMPLATE:
      return `did a good deed`
    case storyTypes.OPP_FEEDBACK:
      if (applicationWithdrawn) return 'withdrew'
      return isOppMasonry ? 'shared feedback' : `shared feedback about a ${l('OPP')}`
    case storyTypes.OPP_HOST_FEEDBACK:
      return isOppMasonry ? 'shared host feedback' : `shared host feedback for a ${l('OPP')}`
    case storyTypes.CAMP_FEATURE:
      return `launched a ${l('CAMP')}`
    case storyTypes.CAMP_UPDATE:
      return `posted a ${l('CAMP')} update`
    case storyTypes.CHALLENGE_CREATED:
      return isOppMasonry ? `created this challenge` : `created a challenge`
    case storyTypes.DONATION_TO_ORG:
      return storyAmount ? `${verb} R${currencyAmount}` : `${verb} money`
    case storyTypes.DONATION_TO_ORG_CONFIRMED:
      return storyAmount ? `donated R${currencyAmount}` : `donated money`
    case storyTypes.NON_PROFIT_MEMBERSHIP_ADDED:
      return storyAmount
        ? `committed to a R${currencyAmount} monthly membership`
        : `committed to a monthly membership`
    case storyTypes.NON_PROFIT_MEMBERSHIP_DONATION_CONFIRMED:
      return storyAmount
        ? `donated R${currencyAmount} as part of a monthly membership`
        : `donated money as part of a monthly membership`
    case storyTypes.OPP_FEATURE:
      return `launched a ${l('OPP')}`
    case storyTypes.OPP_SIGN_UP_CHALLENGE:
      return `joined challenge`
    case storyTypes.OPP_SIGN_UP_VOLUNTEER:
      return isOppMasonry ? 'signed up to volunteer' : `signed up to volunteer for a ${l('OPP')}`
    case storyTypes.OPP_SIGN_UP_GOODS:
      return goodsAmount ? `${verb} ${goodsAmount} ${goodsName}` : `${verb} ${goodsName}`
    case storyTypes.OPP_SIGN_UP_GOODS_CONFIRMED:
      return goodsAmount ? `${verb} ${goodsAmount} ${goodsName}` : `${verb} ${goodsName}`
    case storyTypes.OPP_SIGN_UP_GOODS_CORPORATE:
      return storyAmount ? `${verb} ${storyAmount} ${goodsName}` : `${verb} ${goodsName}`
    case storyTypes.OPP_SIGN_UP_GOODS_CORPORATE_CONFIRMED:
      return goodsAmount ? `${verb} ${storyAmount} ${goodsName}` : `${verb} ${goodsName}`
    case storyTypes.OPP_SIGN_UP_MONEY:
      return storyAmount ? `${verb} R${currencyAmount}` : `${verb} money`
    case storyTypes.OPP_SIGN_UP_MONEY_CORPORATE:
      return storyAmount ? `${verb} R${currencyAmount}` : `${verb} money`
    case storyTypes.OPP_SIGN_UP_MONEY_CONFIRMED:
      return storyAmount ? `donated R${currencyAmount}` : `donated money`
    case storyTypes.OPP_SIGN_UP_MONEY_CORPORATE_CONFIRMED:
      return storyAmount ? `donated R${currencyAmount}` : `donated money`
    case storyTypes.OPP_CREATED:
      return isOppMasonry ? `created this ${l('OPP')}` : `created a ${l('OPP')}`
    case storyTypes.OPP_UPDATE:
      return isOppMasonry ? `updated this ${l('OPP')}` : `updated a ${l('OPP')}`
    case storyTypes.ORG_FEATURE:
      return `posted their philanthropy goals`
    case storyTypes.ORG_INVITE:
      return `grew the Brownie Points network`
    case storyTypes.ORG_PROFILE_COMPLETE:
      return `completed a profile`
    case storyTypes.ORG_UPDATE:
      return `posted an update`
    case storyTypes.CHALLENGE_CHECKIN:
      return `participated in a ${l('OPP')}`
    case storyTypes.SEF_INDICATOR_OUTCOME_SUBMITTED:
      return `provided an update about their outputs`
    case storyTypes.SEF_PARTNER_UPDATE:
      return `shared an update`
    case storyTypes.DGMT_PBO_LISTING:
      return `submitted zero rated resources`
    case storyTypes.DGMT_PBO_LISTING_CONFIRMED:
      return `added zero rated resources`
    default:
      return isOppMasonry ? 'signed up' : `signed up to a ${l('OPP')}`
  }
}

const deriveStoryAction = ({
  applicationWithdrawn,
  beneficiaryName,
  goodsDonationsForStory,
  isOppMasonry,
  orgGoodsDonationsForStory,
  storyAmount,
  storyOutcomeStatus,
  storyType
}) => {
  const action = deriveAction({
    applicationWithdrawn,
    goodsDonationsForStory,
    orgGoodsDonationsForStory,
    isOppMasonry,
    storyAmount,
    storyOutcomeStatus,
    storyType
  })
  const storyHasBeneficiary = beneficiaryName
  const rightSpaceAction = !!storyHasBeneficiary

  return rightSpaceAction ? `${action} ` : action
}

const deterimeBeneficiaryRendering = ({ beneficiaryName, storyType }) => {
  if (!beneficiaryName || !storyType) return false
  if (noBeneficiaryStoryTypes.includes(storyType)) return false

  return true
}

export {
  deriveAttribution,
  deriveGoalTypeIcon,
  deriveStoryAction,
  deriveStoryTypeIcon,
  deterimeBeneficiaryRendering
}

export default {
  deriveAttribution,
  deriveGoalTypeIcon,
  deriveStoryAction,
  deriveStoryTypeIcon,
  deterimeBeneficiaryRendering
}
