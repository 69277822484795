// config
import constants from 'config/constants'

/** SEF role checking
 * @param {Object} roleData - role data from user
 * @returns {Object} - object with booleans for each role
 */
function checkRoles({ roleData }) {
  const isWorkspaceAdmin = roleData.some(r => {
    return (
      r.roleable === constants.ROLEABLES.SEF_WORKSPACE &&
      r.role_type === constants.ROLES.SEF_WORKSPACE_ADMIN
    )
  })

  const isWorkspaceSigningAdmin = roleData.some(r => {
    return (
      r.roleable === constants.ROLEABLES.SEF_WORKSPACE &&
      r.role_type === constants.ROLES.SEF_WORKSPACE_SIGNING_ADMIN
    )
  })

  const isPartnerAdmin = roleData.some(r => {
    return (
      r.roleable === constants.ROLEABLES.ORG && r.role_type === constants.ROLES.SEF_PARTNER_ADMIN
    )
  })

  const isPartnerDistrictOutputsAdmin = roleData.some(r => {
    return (
      r.roleable === constants.ROLEABLES.SEF_PARTNER_DISTRICT &&
      r.role_type === constants.ROLES.SEF_PARTNER_DISTRICT_OUTPUTS_ADMIN
    )
  })

  const isWorksiteOutputsAdmin = roleData.some(r => {
    return (
      r.roleable === constants.ROLEABLES.SEF_WORKSITE &&
      r.role_type === constants.ROLES.SEF_WORKSITE_OUTPUTS_ADMIN
    )
  })

  const isPlatformAdmin = roleData.some(r => {
    return (
      r.roleable === constants.ROLEABLES.SEF_PLATFORM &&
      r.role_type === constants.ROLES.SEF_PLATFORM_ADMIN
    )
  })

  const isPartnerDistrictPeopleAdmin = roleData.some(r => {
    return r.role_type === constants.ROLES.SEF_PARTNER_DISTRICT_PEOPLE_ADMIN
  })

  const isWorksitePeopleAdmin = roleData.some(r => {
    return r.role_type === constants.ROLES.SEF_WORKSITE_PEOPLE_ADMIN
  })

  const isSefMember = roleData.some(r => {
    return r.role_type === constants.ROLES.SEF_ORG_MEMBER
  })

  const isWorksiteOrDistrictAdmin =
    isWorksiteOutputsAdmin ||
    isWorksitePeopleAdmin ||
    isPartnerDistrictOutputsAdmin ||
    isPartnerDistrictPeopleAdmin

  return {
    isPartnerAdmin,
    isPartnerDistrictOutputsAdmin,
    isPlatformAdmin,
    isSefMember,
    isPartnerDistrictPeopleAdmin,
    isWorksitePeopleAdmin,
    isWorksiteOutputsAdmin,
    isWorksiteOrDistrictAdmin,
    isWorkspaceAdmin,
    isWorkspaceSigningAdmin
  }
}

export default checkRoles
