const ordinalizeBillingDay = ({ billingDay }) => {
  if (!billingDay) {
    return { ordinalized: 'Not set', remainingText: '' }
  }

  const stringified = `${billingDay}`
  const stringLength = stringified.length
  const trailingNumber = parseInt(stringified.charAt(stringLength - 1))
  const trailingTwoNumbers = parseInt(`${stringified.charAt(0)}${stringified.charAt(1)}`)

  if ([21, 31].includes(trailingTwoNumbers)) {
    return { ordinalized: `${stringified}st`, remainingText: 'of every month' }
  }

  if ([11, 12, 13].includes(trailingTwoNumbers)) {
    return { ordinalized: `${stringified}th`, remainingText: 'of every month' }
  }

  if (trailingNumber === 1) {
    return { ordinalized: `${stringified}st`, remainingText: 'of every month' }
  } else if (trailingNumber === 2) {
    return { ordinalized: `${stringified}nd`, remainingText: 'of every month' }
  } else if (trailingNumber === 3) {
    return { ordinalized: `${stringified}rd`, remainingText: 'of every month' }
  } else {
    return { ordinalized: `${stringified}th`, remainingText: 'of every month' }
  }
}

export default ordinalizeBillingDay
