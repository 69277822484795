const idsMatch = (a, b) => {
  if (!a || !b) return false

  const aToInt = typeof a === 'string' ? parseInt(a, 10) : a
  const bToInt = typeof b === 'string' ? parseInt(b, 10) : b

  return aToInt == bToInt
}

export default idsMatch
