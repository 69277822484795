import { navigate } from '@reach/router'
import { parse, stringify } from 'qs'

// These helpers are for search filter management. Here is a list of URL parameter abbreviations:
//
// bct            - SEF budget category type
// bt             - An optional beneficiary_type filter used to filter missions
// cid            - An optional campaign_id used to filter missions
// ct             - An optional category filter used to filter for missions
// gt             - An optional goal_type filter used to filter missions
// l              - An optional location search filter used to filter missions
// nt             - An optional filter used to filter by org_type
// oid            - An optional filter used to filter by organisation ID
// ot             - An optional org_type filter used to filter organisations
// page           - The page number of the search query
// tid            - An optional team_id used to filter people
// s              - An optional search query parameter for search input fields
// sd             - An optional start date query parameter for missions
// sf             - Alias for 's' which is a search query parameter for searching mission titles
// st             - subscription donation status
// role           - Used on Growsmart for people filtering
// eventType      - delivery type for email logs
// delivStatus    - delivery status for email logs
// storyCategory  - category_id for stories
// storyType      - story_type for stories

const scrollToResults = () => {
  const resultsContainer = document.getElementById('results')

  if (resultsContainer) {
    const y = resultsContainer.getBoundingClientRect().top + window.pageYOffset - 90
    window.scrollTo({ top: y, behavior: 'smooth' })
  }
}

const handlePageChange = ({ page }) => {
  const urlParams = parse(location.search.replace('?', ''))

  // If there's no page value in the URL, remove the param
  if (!page && !Number.isInteger(page)) {
    delete urlParams['page']
  } else {
    urlParams['page'] = page === 0 ? 1 : page + 1
  }

  // Ensure page value is not 0 and use human-based indexing instead of
  // Javascript 0-based counting
  // if (page && Number.isInteger(page)) {
  //   urlParams['page'] = page === 0 ? 1 : page + 1
  // }

  const stringifiedParams = stringify({ ...urlParams })
  const pathToUse = stringifiedParams.length > 0 ? `?${stringifiedParams}` : ``

  scrollToResults()
  navigate(pathToUse, { replace: true })
}

const toggleFlowMode = () => {
  const urlParams = parse(location.search.replace('?', ''))
  const { perPage } = urlParams
  const newParams = { ...urlParams }

  // enable toggle mode and scroll to top of results, otherwise remove perPage param
  if (!perPage) {
    newParams.perPage = 8
    scrollToResults()
  } else {
    delete newParams.perPage
  }

  const stringifiedParams = stringify({ ...newParams })
  const pathToUse = stringifiedParams.length > 0 ? `?${stringifiedParams}` : ``

  navigate(pathToUse, { replace: true })
}

const handlePageChangeViaArrowKey = event => {
  const { page = '1', perPage } = parse(location.search.replace('?', ''))
  const nextPage = parseInt(page, 10)
  const prevPage = nextPage - 2

  // Left arrow
  // Don't do anything if already on page 1
  if (event.keyCode === 37 && nextPage > 1) {
    const query = { page: prevPage }
    if (perPage) query.perPage = perPage

    handlePageChange(query)
  }

  // Right arrow
  if (event.keyCode === 39) {
    const query = { page: nextPage }
    if (perPage) query.perPage = perPage

    handlePageChange(query)
  }
}

const prepareSearchParams = () => {
  const {
    agreements = '',
    delivStatus = '',
    dist = '',
    eventType = '',
    prov = '',
    sch = '',
    subj = '',
    year = '',
    month = '',
    page = '1',
    partnerId = '',
    perPage = '',
    programName = '',
    role = '',
    cid = '',
    s = '',
    st = '',
    status = '',
    tid = '',
    type = '',
    oid = '',
    oppId = '',
    ot = '',
    storyCategory = '',
    storyType = '',
    verificationStatus = '',
    worksiteId = '',
    workspaceId = ''
  } = parse(location.search.replace('?', ''))
  const firstPage = 1
  const pageInt = parseInt(page, 10)
  const isNegativePage = pageInt < 1
  const boundedPage = Number.isNaN(pageInt) ? firstPage : isNegativePage ? firstPage : pageInt

  return {
    agreements,
    cid,
    delivStatus,
    dist,
    eventType,
    month,
    oid,
    ot,
    oppId,
    perPage,
    page: boundedPage,
    partnerId,
    programName,
    prov,
    role,
    sch,
    s,
    st,
    status,
    storyCategory,
    storyType,
    subj,
    tid,
    type,
    year,
    worksiteId,
    verificationStatus,
    workspaceId
  }
}

const updateSearchFilter = event => {
  const urlParams = parse(location.search.replace('?', ''))
  const newParams = stringify({ ...urlParams, s: event.target.value })
  const appendString = `?${newParams}`
  navigate(appendString, { replace: true })
}

const updateSearchFilterDropdown = ({ value, valType }) => {
  const valueAffectingPaging = value ? encodeURIComponent(value) : null
  const urlParams = parse(location.search.replace('?', ''))
  delete urlParams[valType]

  if (valueAffectingPaging) delete urlParams['page']
  if (valueAffectingPaging) urlParams[valType] = valueAffectingPaging

  const appendString = `?${stringify(urlParams)}`
  navigate(appendString, { replace: true })
}

const updateParamFilter = ({ value, valType }) => {
  const valueAffectingPaging = value ? encodeURIComponent(value) : null
  const urlParams = parse(location.search.replace('?', ''))
  delete urlParams[valType]

  if (valueAffectingPaging) delete urlParams['page']
  if (valueAffectingPaging) urlParams[valType] = valueAffectingPaging

  const appendString = `?${stringify(urlParams)}`
  navigate(appendString, { replace: true })
}

export default {
  handlePageChange,
  handlePageChangeViaArrowKey,
  prepareSearchParams,
  toggleFlowMode,
  updateSearchFilter,
  updateSearchFilterDropdown,
  updateParamFilter
}
export {
  handlePageChange,
  handlePageChangeViaArrowKey,
  prepareSearchParams,
  toggleFlowMode,
  updateSearchFilter,
  updateSearchFilterDropdown,
  updateParamFilter
}
