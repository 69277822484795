import constants from '../config/constants'

const goalTypeOutcomeBuilder = ({
  goalType,
  displayableOutcome = 'good deeds',
  value = 0,
  prefix,
  suffix
}) => {
  const isMoneyGoalType = goalType === constants.OPPORTUNITY_GOAL_TYPES.MONEY

  if (isMoneyGoalType) {
    let displayable = `R${value.toLocaleString('en-US')}.00`

    if (prefix) {
      displayable = `${prefix} ${displayable}`
    }
    if (suffix) {
      displayable = `${displayable} ${suffix}`
    }

    return displayable
  }

  const outcomeLabel = value === 1 ? displayableOutcome.replace(/s$/, '') : displayableOutcome
  let displayable = `${value.toLocaleString('en-US')} ${outcomeLabel}`

  if (prefix) {
    displayable = `${prefix} ${displayable}`
  }
  if (suffix) {
    displayable = `${displayable} ${suffix}`
  }

  return displayable
}

export default goalTypeOutcomeBuilder
