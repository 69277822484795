// assets
import BpSmallLogoSvg from 'assets/images/bp-logo.png'
import BpSmallAltLogoSvg from 'assets/images/bp-logo.png'
import BpFullLogoSvg from 'assets/images/brownie-points-logo-standard.svg'
import BpFullAltLogoSvg from 'assets/images/brownie-points-logo-standard-white.svg'
import BpLoginLogo from 'assets/images/bp-logo.png'

export default {
  loginLogo: BpLoginLogo,
  logoPath: BpFullLogoSvg,
  smallLogoPath: BpSmallLogoSvg,
  smallAltLogoPath: BpSmallAltLogoSvg,
  fullAltLogoPath: BpFullAltLogoSvg,
  dashSidebarLogo: BpFullLogoSvg
}
