const breakpoints = {
  mobile: '320px',
  largeMobile: '480px',
  tablet: '768px',
  desktop: '1024px',
  largeDesktop: '1300px',
  formMiniMapDisplay: '1200px'
}

export default breakpoints
