import React from 'react'

// config
import constants from 'config/constants'
import l from 'config/localization'

// styles
import {
  BeneficiaryDetailsWrapper,
  BeneficiaryIconsWrapper,
  BeneficiaryLink,
  BeneficiaryLogo,
  BeneficiaryNameLabel,
  BeneficiarySectionContainer,
  VerifiedIconWrapper
} from './styles'

// assets
import orgIcon from 'assets/images/icon-group.svg'
import VerifiedIcon from 'assets/images/icons/verified-org.svg'

// components
import AppLoadable from 'components/AppLoadable'

const ConditionalDisplay = AppLoadable({
  loader: () => import('components/ConditionalDisplay/ConditionalDisplay')
})

const BeneficiarySection = ({ beneficiaryOrg, isMobile, isWide, opportunity }) => {
  if (!beneficiaryOrg) return null

  const isVerified = beneficiaryOrg.is_verified
  const logoSrc = beneficiaryOrg.logo_image || orgIcon
  const orgName = beneficiaryOrg.name || ''
  const orgPageLink = `/${l('ROUTE_ORGS')}/${beneficiaryOrg.url_name}`

  const isChallengeOpp = opportunity.goal_type === constants.OPPORTUNITY_GOAL_TYPES.CHALLENGE

  return (
    <BeneficiarySectionContainer isMobile={isMobile} isWide={isWide}>
      <BeneficiaryNameLabel>
        Who is this {isChallengeOpp ? 'challenge' : l('OPP')} for?
      </BeneficiaryNameLabel>

      <BeneficiaryDetailsWrapper>
        <BeneficiaryIconsWrapper>
          <a href={orgPageLink}>
            <BeneficiaryLogo src={logoSrc} />
          </a>

          <BeneficiaryLink href={orgPageLink}>{orgName}</BeneficiaryLink>

          <ConditionalDisplay displayWhen={[isVerified]}>
            <VerifiedIconWrapper src={VerifiedIcon} />
          </ConditionalDisplay>
        </BeneficiaryIconsWrapper>
      </BeneficiaryDetailsWrapper>
    </BeneficiarySectionContainer>
  )
}

export default BeneficiarySection
