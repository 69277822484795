const spacing = {
  miniscule: 3,
  tiny: 6,
  small: 12,
  medium: 18,
  default: 24,
  large: 40,
  gigantic: 64,
  monstrous: 80
}

export default spacing
