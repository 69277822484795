import styled from 'styled-components'
import { Link as ReachRouterLink } from '@reach/router'

// utils
import th from 'utils/themeHelper'
import spacing from 'utils/spacing'

// Load components synchronously
import Link from 'components/Link'
import { Draft } from 'components/Icons/Icons'

export const ActionLink = styled(ReachRouterLink)`
  display: inline-block;
`

export const BeneficiaryLink = styled.a`
  line-height: 1.2;
  margin-left: 15px;
  margin-right: 2px;

  @media (min-width: 769px) {
    margin-left: 5px;
  }

  &:hover {
    text-decoration: underline;
  }
`

export const BeneficiarySectionContainer = styled.div`
  display: ${props => (props.isMobile ? 'block' : 'none')};
  margin: 10px;

  @media (min-width: 769px) {
    display: ${props => (props.isWide ? 'block' : 'none')};
    margin: 0;
  }
`

export const BeneficiaryLogo = styled.div`
  background: white;
  background-image: url(${({ src }) => src});
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  border: 1px solid ${th('borders.default')};
  border-radius: 50%;
  display: block;
  height: 30px;
  margin-right: 5px;
  overflow: hidden;
  position: relative;
  width: 30px;
`

export const VerifiedIconWrapper = styled.div`
  background: white;
  background-image: url(${({ src }) => src});
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 50%;
  display: block;
  height: 30px;
  margin-left: 10px;
  overflow: hidden;
  position: relative;
  width: 30px;

  @media (min-width: 769px) {
    margin-left: 5px;
  }
`

export const ButtonsWrapper = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  margin-top: 50px;
`

export const ButtonWrapper = styled.div`
  margin-right: 20px;
`

export const TeamInviteBenefitHeading = styled.div`
  font-family: ${th('fonts.bold')};
  margin-bottom: 5px;
`

export const DraftHeaderWrapper = styled.div`
  align-items: center;
  display: flex;
  margin-bottom: 1em;
`

export const DraftHeader = styled.h5`
  color: white;
  font-family: ${th('fonts.bold')};
  font-size: 25px;
  line-height: 25px;
  margin: 0;
`

export const DraftIcon = styled(Draft)`
  color: white;
  height: 25px;
  margin-right: 1em;
  margin-top: -3px;
  width: 25px;
`

export const Outer = styled.div`
  background: white;
  border-radius: 3px;
  padding: 0;
`

export const PostSignupSection = styled.div`
  background: white;
  border-radius: 3px;
  line-height: 1.25em;
  overflow-y: auto;
  padding: 0;
`

export const ThanksSection = styled.div`
  background: white;
  border-radius: 3px;
  line-height: 1.25em;
  overflow-y: auto;
  padding: 0;

  p {
    margin-bottom: 0;
  }
`

export const DraftSection = styled.div`
  background: ${props => props.theme.primary.dark};
  border-radius: 3px;
  color: white;
  line-height: 1.25em;
  padding: ${spacing.default}px;
`

export const SignUpFormHint = styled.div`
  color: ${props => (props.warning ? th('text.error') : th('text.dark'))};
  font-size: 12px;
  line-height: 1.35;
  margin-bottom: 15px;
`

export const BeneficiaryDetailsWrapper = styled.div`
  align-items: center;
  display: flex;
  font-family: ${th('fonts.bold')};
  font-size: 15px;
  justify-content: space-between;
  margin: 0;

  @media (min-width: 769px) {
    margin-top: 10px;
  }

  img {
    height: 30px;
    margin-right: 10px;
    width: 30px;
  }
`

export const BorderedSignUpFormLabel = styled.div`
  border-top: 1px solid ${th('backgrounds.mid')};
  font-family: ${th('fonts.bold')};
  font-size: 27px;
  margin-bottom: 5px;
  margin-top: ${props => (props.marginTop ? '20px' : '0')};
  padding-top: ${props => (props.marginTop ? '20px' : '0')};

  @media (min-width: 769px) {
    font-size: 20px;
  }
`

export const SignUpFormLabel = styled.div`
  color: ${props => (props.warning ? th('text.error') : th('text.dark'))};
  font-family: ${th('fonts.bold')};
  font-size: 27px;
  margin-bottom: 5px;
  margin-top: ${props => (props.marginTop ? '20px' : '0')};

  &:first-of-type {
    margin-top: ${props => (props.marginTop ? '5px' : '0')};
  }

  @media (min-width: 769px) {
    font-size: 20px;
  }
`

export const LabelLink = styled(Link)`
  color: ${props => props.theme.text.light};
  font-size: 13px;
  line-height: 1;
  text-decoration: underline;
`

export const BeneficiaryIconsWrapper = styled.div`
  align-items: center;
  display: flex;
`

export const BeneficiaryNameLabel = styled.div`
  color: ${props => (props.warning ? th('text.error') : th('text.dark'))};
  font-family: ${th('fonts.bold')};
  font-size: 12px;
  margin-bottom: 5px;
  margin-left: 2px;
  margin-top: 0;

  @media (min-width: 769px) {
    font-size: 15px;
    margin-bottom: 0;
  }
`
