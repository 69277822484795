const thumbSource = imgSrc => {
  if (!imgSrc) return null

  // new, better, cleaner, less buggier URL format
  if (imgSrc.match('bpimg_')) {
    return imgSrc.replace(/(\.png|\.jpg|\.jpeg|\.gif|\.webp)$/, '-thumb$1')
  }

  const imgThumb = imgSrc.replace(/(\.png|\.jpg|\.jpeg|\.gif|\.webp)$/, '-thumb$1')
  const campaignUrlFormats = ['campaigns/org_', 'campaigns/camp_']
  const newUrlFormats = ['/pending_opps/', '/pending_orgs/', '/logos/']
  const hasCampaignUrl = campaignUrlFormats.some(m => imgSrc.match(m))
  const hasNewUrlFormats = newUrlFormats.some(m => imgSrc.match(m))

  if (hasCampaignUrl) {
    return imgThumb.replace(/(org_.+\/)/, '$1thumbs/')
  }

  if (hasNewUrlFormats) {
    return imgThumb.replace('/logos/', '/logos/thumbs/')
  }

  return imgThumb
    .replace('/opportunities/', '/opportunities/thumbs/')
    .replace('/campaigns/', '/campaigns/thumbs/')
}

export default thumbSource
