const shortVersionOf = s => {
  const string = s || ''

  if (string.includes('Nonprofit')) return 'Nonprofit'
  if (string.includes('Educational')) return 'School'
  if (string.includes('Religious')) return 'Religious'
  if (string.includes('Activist')) return 'Activist group'

  return string
}

const isEmpty = s => {
  return s.trim() === ''
}

const isBlank = s => {
  const stringified = `${s}`
  return s === undefined || s === null || isEmpty(stringified)
}

const isPresent = s => {
  return !isBlank(s)
}

const isUrl = s => {
  return s.match(/^(https?:\/\/)?([^\s\.\/]+\.)?[^\s\.\/]+\.[^\s\.\/]+((\/[^\s\.\/]+)+)?\/?$/i)
}

const capitalize = string => {
  if (!string) return null

  return string.charAt(0).toUpperCase() + string.slice(1)
}

const fileName = s => {
  const segments = decodeURIComponent(s).split('/')
  const segment = segments[segments.length - 1]
  const segmentIsString = typeof s === 'string'

  return segmentIsString ? segment : s.name
}

const trimText = ({ string = '', wordLimit }) => {
  const spliceIndex = wordLimit || string.length
  const originalWorldCount = string
    .trimLeft()
    .split(' ')
    .filter(x => x.length > 0).length

  const splicedString = string
    .trimLeft()
    .split(' ')
    .splice(0, spliceIndex)
    .join(' ')

  const validWords = splicedString.split(' ').filter(x => x.length > 0).length
  const padding = validWords === 1 && string.length === 0 ? 1 : 0
  const wordsRemaining = wordLimit - validWords + padding
  const textWasTrimmed = wordLimit < originalWorldCount
  const finalString = textWasTrimmed ? `${splicedString} [...]` : splicedString

  return { splicedString: finalString, wordsRemaining }
}

export default {
  isEmpty,
  isBlank,
  isPresent,
  isUrl,
  fileName,
  trimText,
  shortVersionOf,
  capitalize
}
