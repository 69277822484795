const isLoggedIn = () => {
  const loggedInCookie = document.cookie.split('; ').find(row => row.startsWith('logged_in='))
  if (!loggedInCookie) return false

  const cookieValue = loggedInCookie.split('=')[1]
  const cookieDate = new Date(cookieValue)
  const twoWeeksAgo = new Date()
  twoWeeksAgo.setDate(twoWeeksAgo.getDate() - 14)

  return cookieDate > twoWeeksAgo
}

export default isLoggedIn
