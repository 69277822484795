const {
  cloneDeep,
  pull,
  isArray,
  forOwn,
  trim,
  isObject,
  isString,
  isEmpty,
  isNaN,
  isNull,
  isUndefined
} = require('lodash')

// strip object of keys with empty values
module.exports = function(obj) {
  return (function clean(current) {
    forOwn(current, function(value, key) {
      if (
        value === 'null' ||
        isUndefined(value) ||
        isNull(value) ||
        isNaN(value) ||
        (isString(value) && !trim(value)) ||
        (isObject(value) && isEmpty(clean(value)))
      ) {
        delete current[key]
      }
    })
    // remove any leftover undefined values from the delete
    // operation on an array
    if (isArray(current)) pull(current, undefined)
    return current
  })(cloneDeep(obj))
}
