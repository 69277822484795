import { split, pipe, trim, pathOr } from "ramda"

const cleanValue = pipe(
  trim,
  split(".")
)

const themeHelper = (valuePath, unit = "") => ({ theme }) =>
  `${pathOr("", cleanValue(valuePath), theme)}${unit}`

export default themeHelper
