// 07:00, 19:00, 30
const calculateIntervals = interval => {
  let minutes = 0
  const intervals = ['00']
  minutes += interval
  while (minutes < 60) {
    const currMins = minutes < 10 ? `0${minutes}` : minutes.toString()
    intervals.push(currMins)
    minutes += interval
  }
  return intervals
}

const generateSlots = (startHour, endHour, interval) => {
  let slots = []
  if (startHour >= endHour) return null
  const intervals = calculateIntervals(interval)
  for (let i = startHour; i < endHour; i++) {
    const currHour = i < 10 ? `0${i}` : i.toString()
    const intervalsInHour = intervals.map(j => `${currHour}:${j}`)
    slots = [...slots, ...intervalsInHour]
  }
  if (endHour !== 24) {
    slots.push(`${endHour}:00`)
  }
  return slots
}

export default generateSlots
