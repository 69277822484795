// config
import { isDgmt } from '../config/localization'

const text = {
  darkest: '#212121',
  dark: '#484848',
  mid: '#696969',
  light: '#898989',
  lightest: '#DDDDDD',
  error: '#EB4525',
  success: '#B7DE6A'
}

const backgrounds = {
  darker: '#484848',
  dark: '#5C5C5C',
  mid: '#DDDDDD',
  light: '#F5F5F5',
  notice: '#379B7530',
  seaBreeze: '#B6BEFF',
  violet: '#ED97F0',
  warning: '#EB4525BB',
  error: '#EB4525'
}

const browniePointsColors = {
  primary: {
    dark: '#6D9B92',
    base: '#379B75',
    light: '#7AD38F',
    lightest: '#bff3cb',
    orange: '#E8A01D'
  },
  secondary: {
    dark: '#2A9044',
    base: '#8CCA11',
    light: '#A8DB43',
    lightest: '#E0F3BB'
  },
  borders: {
    default: '#DDDDDD',
    mid: '#696969',
    medium: '#898989',
    darkPill: '#49414D',
    dark: '#484848',
    error: '#EB4525',
    orange: '#E8A01D',
    success: '#8CCA11',
    required: '#379B75'
  },
  transparent: {
    base: '#379B7533',
    error: '#EB452533',
    baseMedium: '#379B7566',
    orange: '#E8A01D33'
  }
}

const extras = {
  dirtyGold: '#eecb0e'
}

const dgmtColors = {
  primary: {
    dark: '#6D9B92',
    base: '#104D8C',
    light: '#7AD38F',
    lightest: '#bff3cb',
    orange: '#E8A01D'
  },
  secondary: {
    dark: '#2A9044',
    base: '#F37020',
    light: '#A8DB43',
    lightest: '#E0F3BB'
  },
  borders: {
    default: '#DDDDDD',
    medium: '#898989',
    darkPill: '#49414D',
    dark: '#484848',
    error: '#EB4525',
    success: '#8CCA11',
    required: '#379B75'
  },
  transparent: {
    base: '#379B7533',
    baseMedium: '#379B7566',
    error: '#EB452533'
  }
}

const deriveColors = () => {
  if (isDgmt) return dgmtColors

  return browniePointsColors
}

const { primary, secondary, borders, transparent } = deriveColors()

export { text, backgrounds, primary, secondary, borders, extras, transparent }
